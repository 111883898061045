import {
  FUNNEL_NOT_STARTED,
  FUNNEL_QUIZ_COMPLETED,
  FUNNEL_SUBSCRIPTION_ACTIVE,
  FUNNEL_SUBSCRIPTION_INACTIVE,
  COMBO_PRODUCTS__KEYWORDS,
  KEYWORD_SKIN,
  LANDING_PAGES_FOR_SKIN,
  PAGES_FOR_PRODUCT_KEYWORD_BY_APP_EDITION
} from '../../constants/funnels'
import { APP_EDITION_PROVEN } from '../../constants/constants'

/**
 * returns true if the path is in LANDING_PAGES_FOR_SKIN or if appEdition and path match with PAGES_FOR_PRODUCT_KEYWORD_BY_APP_EDITION
 * @param path
 * @param appEdition if it is null/undefined or emtpy it will be used APP_EDITION_PROVEN as default
 */
export function isASkinKeywordProductPage(path, appEdition) {
  const appEditionToUse = appEdition || APP_EDITION_PROVEN
  const skinPagesByAppEdition = PAGES_FOR_PRODUCT_KEYWORD_BY_APP_EDITION[appEditionToUse]
  return ((skinPagesByAppEdition && skinPagesByAppEdition[KEYWORD_SKIN]) || [])
    .concat(LANDING_PAGES_FOR_SKIN)
    .some(url => path?.indexOf(url) > -1)
}

export function determineFunnelValue({
  completedProductQuiz,
  activeSubscriptions,
  inactiveSubscriptions,
  productSubscriptionFilter
}) {
  const isProductSubActive = !!activeSubscriptions?.find(sub => productSubscriptionFilter(sub))
  const isProductSubInactive = !!inactiveSubscriptions?.find(sub => productSubscriptionFilter(sub))

  if (isProductSubActive) {
    return FUNNEL_SUBSCRIPTION_ACTIVE
  } else if (isProductSubInactive) {
    return FUNNEL_SUBSCRIPTION_INACTIVE
  } else if (completedProductQuiz) {
    return FUNNEL_QUIZ_COMPLETED
  } else {
    return FUNNEL_NOT_STARTED
  }
}

export function determineFunnel({ completedQuiz, isSubscriber }) {
  if (isSubscriber) {
    return FUNNEL_SUBSCRIPTION_ACTIVE
  } else if (completedQuiz) {
    return FUNNEL_QUIZ_COMPLETED
  } else {
    return FUNNEL_NOT_STARTED
  }
}

/**
 *  Return the funnel (keyword) included in the path. If no keyword is found it returns null
 * @param pathname
 * @returns {string|null}
 */
export function determineFunnelValueByPath(pathname) {
  if (!pathname) {
    console.warn(
      'funnel value cannot be determined since pathname is: ',
      pathname,
      ' It will return null'
    )
    return null
  }
  const funnel = COMBO_PRODUCTS__KEYWORDS.find(keyword => pathname.includes(keyword))
  return funnel ? funnel : null
}
