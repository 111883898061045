import { onDesktop } from '../responsive'
import { experimental_sx as sx } from '@mui/material/styles'

export const onDeviceThatSupportHover = `@media (hover: hover)`

export const prepareButton = theme => {
  return {
    defaultProps: {
      disableRipple: true,
      disableElevation: true
    },
    styleOverrides: {
      root: sx({
        boxShadow: theme.shadows[1],
        '&.Mui-disabled': {
          boxShadow: theme.shadows[1],
          color: theme.palette.gray.middleGray,
          backgroundColor: theme.palette.gray.lightGray
        },
        '& .MuiButton-iconSizeSmall svg': {
          fontSize: '0.5rem'
        },
        '& .MuiButton-iconSizeMedium svg': {
          fontSize: '0.875rem'
        },
        '& .MuiButton-iconSizeLarge svg': {
          fontSize: theme.icons.large.fontSize
        }
      }),
      disableElevation: sx({
        boxShadow: theme.shadows[0],
        '&.Mui-disabled': {
          boxShadow: theme.shadows[0]
        }
      }),
      sizeMedium: {
        height: 44,
        borderRadius: 22,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        [onDesktop]: {
          height: theme.spacing(6),
          borderRadius: 24
        }
      },
      sizeSmall: {
        height: 30,
        borderRadius: 15,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        [onDesktop]: {
          height: theme.spacing(4),
          borderRadius: 16
        }
      },
      disabled: sx({
        color: theme.palette.gray.middleGray,
        '&.MuiButton-contained': {
          backgroundColor: theme.palette.gray.lightGray
        }
      }),
      containedPrimary: sx({
        backgroundColor: theme.palette.gray.elysian,
        color: theme.palette.common.white,
        '&:hover': {
          [onDeviceThatSupportHover]: {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.gray.elysian
          }
        },
        '&.MuiButton-sizeSmall': {
          backgroundColor: theme.palette.primary.main,
          '&.Mui-disabled': {
            boxShadow: theme.shadows[1],
            color: theme.palette.gray.middleGray,
            backgroundColor: theme.palette.gray.lightGray
          },
          '&:hover': {
            [onDeviceThatSupportHover]: {
              backgroundColor: theme.palette.primary.main
            }
          }
        }
      }),
      containedSecondary: sx({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.gray.elysian,
        '&:hover': {
          [onDeviceThatSupportHover]: {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main
          },
          '& span': {
            [onDeviceThatSupportHover]: {
              transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              color: `${theme.palette.secondary.main}`
            }
          },
          '& s': {
            [onDeviceThatSupportHover]: {
              transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              color: `${theme.palette.secondary.main}`
            }
          }
        },
        '& span': {
          transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          color: theme.palette.gray.elysian
        },
        '& s': {
          transition: 'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          color: theme.palette.gray.blueGray
        }
      }),
      outlinedPrimary: sx({
        color: theme.palette.gray.elysian,
        borderColor: theme.palette.gray.middleGray,
        '&:hover': {
          [onDeviceThatSupportHover]: {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            background: 'none'
          }
        }
      }),
      containedInfo: sx({
        textTransform: 'initial',
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.gray.elysian,
        borderRadius: 8,
        letterSpacing: 0,
        '&:hover': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.gray.elysian
        }
      }),
      outlinedInfo: sx({
        textTransform: 'initial',
        color: theme.palette.gray.elysian,
        borderColor: theme.palette.gray.lightBlue,
        borderRadius: 8,
        letterSpacing: 0,
        '&:hover': {
          color: theme.palette.gray.elysian,
          borderColor: theme.palette.gray.lightBlue,
          background: 'none'
        }
      }),
      text: sx({
        '&:hover': {
          [onDeviceThatSupportHover]: {
            background: 'none'
          }
        }
      }),
      textPrimary: sx({
        color: theme.palette.gray.elysian,
        '&:hover': {
          [onDeviceThatSupportHover]: {
            color: theme.palette.primary.main
          }
        }
      }),
      icon: {
        minWidth: 'unset',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        '&:hover': {
          [onDeviceThatSupportHover]: {
            background: 'none'
          }
        }
      },
      iconPrimary: {
        minWidth: 'unset',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        color: theme.palette.gray.elysian,
        '&:hover': {
          [onDeviceThatSupportHover]: {
            color: theme.palette.primary.main
          }
        }
      }
    }
  }
}
