import { pathOr } from 'ramda'
import { FUNNEL_QUIZ_COMPLETED } from '../../constants/funnels'
import { determineFunnel } from './funnels'
import userTypeAccessRoot from '../../constants/user/user-type-access'
import {
  ACTIVE_SUBSCRIBER,
  PURCHASER,
  ONLY_ONE_TIME_PURCHASER,
  SUBSCRIBER,
  PROSPECT,
  EMPTY_USER
} from '../../constants/user/user-type'
import {
  HAS_SYSTEM_ACTIVE_SUBSCRIPTION,
  HAS_EYE_CREAM_DUO_ONE_TIME,
  HAS_SYSTEM_ONE_TIME,
  HAS_EYE_PROFILE,
  HAS_SKIN_PROFILE,
  HAS_SERUM_PROFILE
} from '../../constants/user/user-trait'
import { CRM_REDIRECT_PARAMS_MAPPING } from '../../constants/user/event-name'
import { CRM_REDIRECT_PARAMS_MAPPING_VALUES } from '../../constants/constants'

const withoutSlashAtTheEnd = p => (p.endsWith('/') ? p.slice(0, -1) : p)

const isAllowed = (path, userTypeAccess) => {
  return (
    userTypeAccess &&
    (userTypeAccess.allowedPaths || []).some(p => {
      return path && withoutSlashAtTheEnd(p) === withoutSlashAtTheEnd(path)
    })
  )
}

const getRedirectionInUserTypeAccess = (pathOrEventName, userTypeAccess) => {
  return (
    pathOrEventName &&
    userTypeAccess.redirectionByPathOrEventName &&
    userTypeAccess.redirectionByPathOrEventName[withoutSlashAtTheEnd(pathOrEventName)]
  )
}

const isAllowedInAnyUserTypeAccess = (path, hierarchicalUserTypeNames) => {
  const userTypesNames = [...hierarchicalUserTypeNames]
  for (let i = 0; i < hierarchicalUserTypeNames.length; i++) {
    const userTypeAccess = pathOr(null, userTypesNames, userTypeAccessRoot)
    if (isAllowed(path, userTypeAccess)) return true
    userTypesNames.pop()
  }
  return false
}

const getRedirectionPathInAnyUserTypeAccess = (path, hierarchicalUserTypeNames) => {
  const userTypesNames = [...hierarchicalUserTypeNames]
  for (let i = 0; i < hierarchicalUserTypeNames.length; i++) {
    const userTypeAccess = pathOr(null, userTypesNames, userTypeAccessRoot)
    const redirectionPath = getRedirectionInUserTypeAccess(path, userTypeAccess)
    if (redirectionPath) return redirectionPath
    userTypesNames.pop()
  }
  return undefined
}

const isOneTimePurchaser = ({ activeSubscriptions, orders }) => {
  return !activeSubscriptions.length && orders.length
}
const isSystemOneTimePurchaser = ({ activeSubscriptions, orders }) => {
  //TODO stop using order.description to identify product. Make backend return ids of products in 'orders/search' endpoint to identify product type
  return (
    isOneTimePurchaser({ activeSubscriptions, orders }) &&
    orders.some(o => o?.description?.split(',').find(p => p === 'Proven System'))
  )
}
const isEyeCreamDuoOneTimePurchaser = ({ activeSubscriptions, orders }) => {
  //TODO stop using order.description to identify product. Make backend return ids of products in 'orders/search' endpoint to identify product type
  return (
    isOneTimePurchaser({ activeSubscriptions, orders }) &&
    orders.some(o => o?.description?.split(',').find(p => p === 'Eye Cream Duo'))
  )
}

const isProspect = productsFunnels => {
  const maxFunnel = productsFunnels.reduce(
    (maxFunnel, currentFunnel) => (currentFunnel > maxFunnel ? currentFunnel : maxFunnel),
    0
  )
  return maxFunnel === FUNNEL_QUIZ_COMPLETED
}

export const hasAccessToPath = (
  path,
  {
    activeSubscriptions,
    orders,
    completedSkinQuiz,
    completedEyeQuiz,
    completedSerumQuiz,
    isSystemSubscriber,
    isEyeCreamDuoSubscriber,
    isSerumSubscriber
  }
) => {
  return !!findValueInAccessTypeByEventNameOrPath(
    path,
    {
      activeSubscriptions,
      orders,
      completedSkinQuiz,
      completedEyeQuiz,
      completedSerumQuiz,
      isSystemSubscriber,
      isEyeCreamDuoSubscriber,
      isSerumSubscriber
    },
    isAllowedInAnyUserTypeAccess
  )
}

export const getRedirectionByEventNameAndPath = (
  eventName,
  path,
  {
    activeSubscriptions,
    orders,
    completedSkinQuiz,
    completedEyeQuiz,
    completedSerumQuiz,
    isSystemSubscriber,
    isEyeCreamDuoSubscriber,
    isSerumSubscriber
  }
) => {
  const pathWithoutSlash = withoutSlashAtTheEnd(
    withoutSlashAtTheEnd(path).split('').reverse().join('')
  )
    .split('')
    .reverse()
    .join('')
  const eventNameWithPath = `/${pathWithoutSlash}${eventName}`
  return getRedirectionByEventNameOrPath(eventNameWithPath, {
    activeSubscriptions,
    orders,
    completedSkinQuiz,
    completedEyeQuiz,
    completedSerumQuiz,
    isSystemSubscriber,
    isEyeCreamDuoSubscriber,
    isSerumSubscriber
  })
}

export const getRedirectionByEventNameOrPath = (
  eventNameOrPath,
  { activeSubscriptions, orders, completedSkinQuiz, completedEyeQuiz, completedSerumQuiz }
) => {
  return findValueInAccessTypeByEventNameOrPath(
    eventNameOrPath,
    {
      activeSubscriptions,
      orders,
      completedSkinQuiz,
      completedEyeQuiz,
      completedSerumQuiz
    },
    getRedirectionPathInAnyUserTypeAccess
  )
}

export const getMLEventFromParms = params => {
  const paramsStr = params.toString()
  for (let i = 0; i < CRM_REDIRECT_PARAMS_MAPPING.length; i++) {
    const redirectEvent = CRM_REDIRECT_PARAMS_MAPPING[i]
    if (paramsStr.includes(redirectEvent)) {
      return redirectEvent
    }
  }
  return undefined
}

export const getMLRedirectUrlFromParams = params => {
  for (const [key, value] of Object.entries(CRM_REDIRECT_PARAMS_MAPPING_VALUES)) {
    if (params.includes(key)) {
      return value
    }
  }
}

const findValueInAccessTypeByEventNameOrPath = (
  eventNameOrPath,
  {
    activeSubscriptions,
    orders,
    completedSkinQuiz,
    completedEyeQuiz,
    completedSerumQuiz,
    isSystemSubscriber,
    isEyeCreamDuoSubscriber,
    isSerumSubscriber
  },
  getValueInAnyUserTypeAccess
) => {
  const eyeFunnel = determineFunnel({
    completedQuiz: completedEyeQuiz,
    isSubscriber: isEyeCreamDuoSubscriber
  })

  const skinFunnel = determineFunnel({
    completedQuiz: completedSkinQuiz,
    isSubscriber: isSystemSubscriber
  })

  const serumFunnel = determineFunnel({
    completedQuiz: completedSerumQuiz,
    isSubscriber: isSerumSubscriber
  })

  if (activeSubscriptions.length) {
    const hierarchicalUserTypeNames = [PURCHASER, SUBSCRIBER, ACTIVE_SUBSCRIBER]
    if (isSystemSubscriber) hierarchicalUserTypeNames.push(HAS_SYSTEM_ACTIVE_SUBSCRIPTION)
    return getValueInAnyUserTypeAccess(eventNameOrPath, hierarchicalUserTypeNames)
  } else if (isOneTimePurchaser({ activeSubscriptions, orders })) {
    const hierarchicalUserTypeNames = [PURCHASER, ONLY_ONE_TIME_PURCHASER]
    let valueFound = undefined
    if (isSystemOneTimePurchaser({ activeSubscriptions, orders })) {
      valueFound = getValueInAnyUserTypeAccess(eventNameOrPath, [
        ...hierarchicalUserTypeNames,
        HAS_SYSTEM_ONE_TIME
      ])
    }
    if (!valueFound && isEyeCreamDuoOneTimePurchaser({ activeSubscriptions, orders })) {
      valueFound = getValueInAnyUserTypeAccess(eventNameOrPath, [
        ...hierarchicalUserTypeNames,
        HAS_EYE_CREAM_DUO_ONE_TIME
      ])
    }
    if (!valueFound) {
      valueFound = getValueInAnyUserTypeAccess(eventNameOrPath, hierarchicalUserTypeNames)
    }
    return valueFound
  } else if (isProspect([skinFunnel, eyeFunnel, serumFunnel])) {
    if (completedSkinQuiz) {
      return getValueInAnyUserTypeAccess(eventNameOrPath, [PROSPECT, HAS_SKIN_PROFILE])
    }
    if (completedEyeQuiz) {
      return getValueInAnyUserTypeAccess(eventNameOrPath, [PROSPECT, HAS_EYE_PROFILE])
    }
    if (completedSerumQuiz) {
      return getValueInAnyUserTypeAccess(eventNameOrPath, [PROSPECT, HAS_SERUM_PROFILE])
    }
    console.error('Prospect user should have at least one profile (eye profile or skin profile')
    return getValueInAnyUserTypeAccess(eventNameOrPath, [PROSPECT])
  } else {
    // EMPTY_USER
    return getValueInAnyUserTypeAccess(eventNameOrPath, [EMPTY_USER])
  }
}
