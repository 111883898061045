import { isEmpty, pathOr } from 'ramda'
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator'
import moment from 'moment'
import { countries } from '@provenai/shared'
import { add, cloneDeep, debounce } from 'lodash'
import {
  DEFAULT_SYSTEM_SUBSCRIPTION_PERIOD,
  DEFAULT_SUBSCRIPTION_PERIOD_UNIT
} from 'constants/subscriptions'
import validations from '../constants/configs/validations'
import {
  DEFAULT_EYE_CREAM_DUO_SUBSCRIPTION_PERIOD,
  DEFAULT_SERUM_SUBSCRIPTION_PERIOD,
  PRICE_ID_SUBSTRING_FOR_SUBSCRIPTION_ITEM
} from '../constants/subscriptions'

import jwt from 'jwt-decode'
import {
  CLEANSER_PRODUCT,
  COMBO_PRODUCTS,
  DAY_EYE_CREAM_PRODUCT,
  DAY_MOISTURIZER_PRODUCT,
  EYE_CREAM_PRODUCT,
  NIGHT_CREAM_PRODUCT,
  NIGHT_EYE_CREAM_PRODUCT,
  SYSTEM_PRODUCT,
  SERUM_PRODUCT,
  ALL_SHOP_PRODUCTS,
  SERUM
} from '../constants/products'
import { SKIP_THE_LINE_PRODUCT_ID } from '../constants/skip-the-line'

export const getGroupProgress = ({ name, group, questions }) => {
  const groupQuestions = questions.filter(
    question => pathOr(null, ['question', 'group'], question) === group
  )
  const index = groupQuestions.findIndex(
    question => pathOr(null, ['question', 'name'], question) === name
  )

  return ((index + 1) / groupQuestions.length) * 100
}

export const validateField = async (question, answer, countryCode) => {
  const { maxAnswers, name, optional } = question
  if (optional) {
    return
  }

  //Check if some questions are empty
  if (isEmpty(answer)) {
    if (name === 'zip') {
      return validations.zip
    } else if (name === 'name') {
      return validations.name
    } else if (['phone'].includes(name)) {
      return false //allowed to be empty
    } else {
      return validations.empty
    }
  }

  //Validate some questions
  if (maxAnswers && answer.length < maxAnswers) {
    return validations.selectLimit(maxAnswers)
  }
  if (name === 'zip') {
    return !validateZip(answer, countryCode) ? validations.zip : false
  }
  if (name === 'date') {
    return !validateDate(answer) ? validations.date : false
  }
  if (name === 'phone') {
    return !validatePhoneNumber(answer) ? validations.phone : false
  }
}

export const required = value => {
  return value && value !== '+' && value?.trim() !== '' ? undefined : 'This field is required.'
}

export const email = email =>
  validateRawEmail(email) ? undefined : 'This email address is invalid.'

// TODO we are not using validatePhoneNumber() anymore. should be removed in the future.
export const validatePhoneNumber = phone => {
  if (phone === undefined || phone === '') return true
  // const re = /^(0|[1-9][0-9]{9})$/i // ToDo(@charles) validate phone number based on country code
  return true
}

export const removePhoneMask = phone => phone.replace(/(\(|\)|-|\s)/g, '')

export const validateZip = (zip, countryCode) => {
  if (countryCode && postcodeValidatorExistsForCountry(countryCode)) {
    return postcodeValidator(zip, countryCode)
  }

  return true
}

export const validateEmail = async (email, getEmailVerification) => {
  try {
    let isEmailValid = validateRawEmail(email)
    if (!isEmailValid) return { result: false, suggestedEmail: '', reason: null }

    // Extra validation kick box
    let emailVerificationResult = await getEmailVerification(email)
    if (emailVerificationResult.result !== 'undeliverable')
      return { result: true, suggestedEmail: '', reason: null }
    return {
      result: false,
      reason: 'Invalid Email.',
      suggestedEmail: emailVerificationResult.did_you_mean ?? ''
    }
  } catch (error) {
    return null
  }
}

export const asyncDebounce = (func, wait) => {
  const debounced = debounce((resolve, reject, args) => {
    func(...args)
      .then(resolve)
      .catch(reject)
  }, wait)
  return (...args) =>
    new Promise((resolve, reject) => {
      debounced(resolve, reject, args)
    })
}

export const validateRawEmail = email => {
  if (email === undefined) return true
  /* eslint-disable */
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validateState = state =>
  /^(?:(A[BKLRZ]|BC|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ABDEINOST]|N[BCDEHJLMSTUVY]|O[HNKR]|P[AER]|QC|RI|S[CDK]|T[NX]|UT|V[AIT]|W[AIVY]|YT))$/.test(
    String(state).toUpperCase()
  )

export const validateDate = date => /^(19|20)\d{2}$/.test(date)

export const isProd = () => process.env.NODE_ENV === 'production'

export const countryValid = value => {
  return countries.active.findIndex(country => country.code === value) > -1
    ? undefined
    : 'Invalid country'
}

// ToDo(@charles) move this function into shared library
export const getProvenCurrencyForCountry = countryCode => {
  const country = countries.active.find(c => c.code === countryCode)
  return country ? country.provenCurrency : 'USD'
}

export const stateValid = value => {
  // return validateState(value) ? undefined : 'Invalid state'
  return undefined
}

export const zipValid = (value, formValues) => {
  return validateZip(value, formValues.country) ? undefined : 'Invalid zip'
}

export const numberWithCommas = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const randomString = () => Math.random().toString(36).substr(2, 5)

export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined

export const minLength6 = minLength(6)

export const canUseDOM = () => {
  return !!(typeof window !== 'undefined' && window.document && window.document.createElement)
}

export const getQueryStringValue = varName => {
  const params = new URLSearchParams(window.location.search)
  return params.get(varName)
}

export const isEqual = (value, other) => {
  // Get the value type
  const type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false

  // Compare the length of the length of the two items
  const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length
  const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) return false

  // Compare two items
  const compare = function (item1, item2) {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1)

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false
      } else {
        if (item1 !== item2) return false
      }
    }
  }

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false
      }
    }
  }

  // If nothing failed, return true
  return true
}

export const isTextSelected = () => {
  const { type } = window.getSelection()
  return type === 'Range'
}

export const isOrderPendingShipment = order => {
  return (
    order.warehouseStatus.some(warehouseObject => 'Completed' in warehouseObject) &&
    'pre_transit' in order.shippingStatus[0]
  )
}

export const getSkuType = sku => {
  if (!sku) return null
  if (sku.indexOf('SPF') !== -1 || sku.indexOf('DAY') !== -1) return 'SPF'
  if (sku.indexOf('CLN') !== -1) return 'CLN'
  if (sku.indexOf('EYE') !== -1) return 'EYE'
  if (sku.indexOf('CRM') !== -1) return 'CRM'
  if (sku.indexOf('SRM') !== -1) return 'SRM'
  return null
}

export const getProductIdEye = sku => {
  if (sku.indexOf('EYE-1') !== -1) return 'day-eye-cream'
  if (sku.indexOf('EYE-2') !== -1) return 'night-eye-cream'
  if (sku.indexOf('EYE') !== -1) return 'eye-cream-duo'
  return null
}

export const filterObj = ({ obj, fn }) => Object.fromEntries(Object.entries(obj).filter(fn))

export const mapObj = ({ obj, fn }) => Object.fromEntries(Object.entries(obj).map(fn))

export const addBusinessDays = numDaysToAdd => {
  return addBusinessDaysToDate(moment(), numDaysToAdd)
  // const sunday = 0
  // const saturday = 6
  // let daysRemaining = numDaysToAdd

  // const now = moment()
  // const newDate = now.clone()

  // while (daysRemaining > 0) {
  //   newDate.add(1, 'days')
  //   if (newDate.day() !== sunday && newDate.day() !== saturday) {
  //     daysRemaining--
  //   }
  // }

  // return newDate
}

export const addBusinessDaysToDate = (startDate, numDaysToAdd) => {
  const sunday = 0
  const saturday = 6
  let daysRemaining = numDaysToAdd

  const newDate = startDate.clone()

  while (daysRemaining > 0) {
    newDate.add(1, 'days')
    if (newDate.day() !== sunday && newDate.day() !== saturday) {
      daysRemaining--
    }
  }

  return newDate
}

export const getSystemInCart = ({ products, cartItems, isSystemOnly }) => {
  const priceId = Object.keys(cartItems).find(priceId => {
    const product = findCartProductWithPriceId({ products, priceId })
    return isSystemOnly
      ? product?.metadata?.isSystem
      : product?.metadata?.isSystem || product?.metadata?.isPartOfSystem
  })
  if (priceId) {
    return findPriceInProducts({ products, priceId })
  }
  return { price: null, oneTime: false }
}

export const isItemInCart = ({ priceId, cartItems }) => {
  const item = cartItems[priceId]
  return item && item.qty > 0
}

export const isPriceIdInProduct = ({ product, priceId }) => {
  const prices = [...(product.subscription_prices || []), product.one_time_price]
  for (const price of prices) {
    if (price.id?.toLowerCase() === priceId?.toLowerCase()) {
      return true
    }
  }
  return false
}

/**
 * return true if priceId is in accessories' price
 * @param accessories
 * @param priceId
 * @returns {boolean}
 */
export const isPriceIdInAccessories = ({ accessories, priceId }) => {
  const item = accessories?.find(accessory => accessory?.price?.id === priceId)
  return !!item
}

export const findCartProductWithPriceId = ({ products, priceId }) => {
  return products.find(someProduct => isPriceIdInProduct({ product: someProduct, priceId }))
}

export const findProductForPriceId = ({ products, priceId, includingNotBuyable }) => {
  return products.find(someProduct => {
    if (!includingNotBuyable && !someProduct.buyable) return false
    return isPriceIdInProduct({ product: someProduct, priceId })
  })
}

/**
 * search "priceId" in all "products" and return TRUE if the product exist and is buyable
 * @param products
 * @param priceId
 * @returns {boolean}
 */
export const isProductBuyableForPriceId = ({ products, priceId }) => {
  const product = products.find(someProduct => {
    return isPriceIdInProduct({ product: someProduct, priceId })
  })

  return !!product?.buyable
}

export const findAccessoryForPriceId = ({ accessories, priceId }) => {
  return accessories.find(someAccessory => someAccessory.price.id === priceId)
}

export const findPriceInProduct = ({ product, priceId }) => {
  if (product?.one_time_price.id === priceId) {
    return {
      price: product.one_time_price,
      oneTime: true
    }
  } else {
    return {
      price: product?.subscription_prices.find(somePrice => somePrice.id === priceId),
      oneTime: false
    }
  }
}

export const findPriceInProducts = ({ products, priceId }) => {
  const product = findProductForPriceId({ products, priceId, includingNotBuyable: true })
  return findPriceInProduct({ product, priceId })
}

export const getItemsTotalQuantity = items => {
  return Object.values(items).reduce((acc, qty) => {
    return acc + qty
  }, 0)
}

export const getItemsQuantity = cartItems => {
  return Object.keys(cartItems).length
}

export const getItemQuantity = (cartItems, priceId) => {
  return cartItems && cartItems[priceId] && cartItems[priceId]?.qty ? cartItems[priceId]?.qty : 0
}

/**
 * this function return a list of one-time buyables items, this list do NOT include skip-the-line item
 * @param items
 * @param products
 * @returns {[string, unknown][]}
 */
export const getCartIndividualItems = ({ items, products }) => {
  return Object.entries(items).filter(([priceId]) => {
    return (
      isProductBuyableForPriceId({ priceId, products }) &&
      isOneTimeProduct(priceId, products) &&
      !isSystemProductForPriceId({ priceId, products })
    )
  })
}

/**
 * this function return a list of accessories in the cart
 * @param items
 * @param accessories
 * @returns {[string, unknown][]}
 */
export const getCartAccessoriesItems = ({ items, products }) => {
  return Object.entries(items).filter(([priceId]) => {
    return (
      isProductBuyableForPriceId({ priceId, products }) &&
      isSAccessoryForPriceId({ priceId, products })
    )
  })
}

/**
 * return all individual products (no subscriptions or skip-the-line)
 * @param items
 * @param products
 * @returns {{product: *, qty: *, priceId: *}[]}
 */
export const getCartItemAndIndividualProducts = ({ items, products }) => {
  return Object.entries(items)
    .map(([priceId, data]) => ({
      priceId,
      qty: data.qty,
      product: findProductForPriceId({ products, priceId })
    }))
    .filter(({ product, priceId }) => {
      return (
        isProductBuyableForPriceId({ priceId, products }) &&
        isOneTimeProduct(priceId, products) &&
        !isSystemProductForPriceId({ priceId, products })
      )
    })
}

export const cartHasIndividualProducts = ({ items, products }) => {
  return getCartIndividualItems({ items, products }).length > 0
}

export const getItemsTotalPrice = ({ items, products }) => {
  const totalPriceWithDecimals = Object.entries(items).reduce((acc, [priceId, item]) => {
    const { price } = findPriceInProducts({ products, priceId })
    if (price) return acc + item.qty * price.price
    else return acc
  }, 0)
  return formatPrice(totalPriceWithDecimals)
}

export const convertSubscriptionItemsToNonSystemItems = ({ subscription, products }) => {
  if (!subscription?.items) return null
  const nonSystemProducts = products.filter(
    p => !p.metadata?.isSystem && p.metadata?.isPartOfSystem
  )
  const systemProduct = products.find(p => p.metadata?.isSystem)
  const systemOneTimePrice = systemProduct.one_time_price
  const systemSubscriptionPrice = findPriceInProductByPeriod({
    product: systemProduct,
    period: subscription.frequency,
    periodUnit: subscription.frequencyUnit
  })
  const systemOneTimeQuantity = subscription.items[systemOneTimePrice.id]?.quantity ?? 0
  const systemSubscriptionQuantity = subscription.items[systemSubscriptionPrice.id]?.quantity ?? 0
  return Object.fromEntries(
    nonSystemProducts
      .map(product => {
        const oneTimePrice = product.one_time_price
        const subscriptionPrice = product.subscription_prices.find(
          somePrice =>
            somePrice.period === subscription.frequency &&
            somePrice.period_unit === subscription.frequencyUnit
        )
        const oneTimeItem = subscription.items[oneTimePrice.id]
        const subscriptionItem = subscription.items[subscriptionPrice.id]
        const oldOneTimeQuantity = oneTimeItem?.quantity ?? 0
        const oldSubscriptionQuantity = subscriptionItem?.quantity ?? 0
        const newOneTimeQuantity = oldOneTimeQuantity + systemOneTimeQuantity
        const newOneTimeUnitPrice = oneTimeItem?.unitPrice ?? oneTimePrice.price
        const newOneTimeTotalPrice = newOneTimeQuantity * newOneTimeUnitPrice
        const newSubscriptionQuantity = oldSubscriptionQuantity + systemSubscriptionQuantity
        const newSubscriptionUnitPrice = subscriptionItem?.unitPrice ?? subscriptionPrice.price
        const newSubscriptionTotalPrice = newSubscriptionQuantity * newSubscriptionUnitPrice
        return [
          [
            subscriptionPrice.id,
            {
              quantity: newSubscriptionQuantity,
              unitPrice: newSubscriptionUnitPrice,
              totalPrice: newSubscriptionTotalPrice
            }
          ],
          [
            oneTimePrice?.id,
            {
              quantity: newOneTimeQuantity,
              unitPrice: newOneTimeUnitPrice,
              totalPrice: newOneTimeTotalPrice
            }
          ]
        ]
      })
      .flat()
  )
}

export const addSystemsToItemsAndClearEmpty = ({
  items,
  frequency: period,
  frequencyUnit: periodUnit,
  products
}) => {
  const nonSystemProducts = products.filter(
    p => !p.metadata?.isSystem && p.metadata?.isPartOfSystem
  )
  const systemProduct = products.find(p => p.metadata?.isSystem)
  const systemOneTimePrice = systemProduct.one_time_price
  const systemSubscriptionPrice = findPriceInProductByPeriod({
    product: systemProduct,
    period,
    periodUnit
  })
  const systemOneTimeQuantities = [],
    systemSubscriptionQuantities = []
  for (const product of nonSystemProducts) {
    const productOneTimePrice = product.one_time_price
    const productSubscriptionPrice = findPriceInProductByPeriod({
      product,
      period,
      periodUnit
    })
    systemOneTimeQuantities.push(items[productOneTimePrice.id].quantity)
    systemSubscriptionQuantities.push(items[productSubscriptionPrice.id].quantity)
  }
  const systemOneTimeQuantity = systemOneTimeQuantities.includes(0)
    ? 0
    : systemOneTimeQuantities.reduce((a, b) => Math.min(a, b))
  const systemSubscriptionQuantity = systemSubscriptionQuantities.includes(0)
    ? 0
    : systemSubscriptionQuantities.reduce((a, b) => Math.min(a, b))
  const systemItems = [
    [
      systemOneTimePrice.id,
      {
        quantity: systemOneTimeQuantity,
        unitPrice: systemOneTimePrice.price,
        totalPrice: systemOneTimePrice.price * systemOneTimeQuantity
      }
    ],
    [
      systemSubscriptionPrice.id,
      {
        quantity: systemSubscriptionQuantity,
        unitPrice: systemSubscriptionPrice.price,
        totalPrice: systemSubscriptionPrice.price * systemSubscriptionQuantity
      }
    ]
  ]
  const itemsWithSystemQuantitiesRemoved = nonSystemProducts
    .map(product => {
      const oneTimePrice = product.one_time_price
      const subscriptionPrice = findPriceInProductByPeriod({
        product,
        period,
        periodUnit
      })
      const oneTimeItem = items[oneTimePrice.id]
      const subscriptionItem = items[subscriptionPrice.id]
      return [
        [
          oneTimePrice.id,
          {
            ...oneTimeItem,
            quantity: oneTimeItem.quantity - systemOneTimeQuantity
          }
        ],
        [
          subscriptionPrice.id,
          {
            ...subscriptionItem,
            quantity: subscriptionItem.quantity - systemSubscriptionQuantity
          }
        ]
      ]
    })
    .flat()
  const allItems = itemsWithSystemQuantitiesRemoved.concat(systemItems)
  const allItemsWithQuantities = allItems.filter(([_priceId, item]) => item.quantity > 0)
  return Object.fromEntries(allItemsWithQuantities)
}

export const calcNonSystemProductsTotalPrice = ({ products, period, periodUnit }) => {
  return products
    .filter(p => p.metadata?.isPartOfSystem)
    .reduce((acc, product) => {
      const productPrice =
        period && periodUnit
          ? findPriceInProductByPeriod({
              product,
              period,
              periodUnit
            })
          : product.one_time_price
      return acc + productPrice.price
    }, 0)
}

/**
 * @returns {number} of total price of the Eye Cream Duo before the discount.
 *  It's a sum of all individual Eye products' one-time prices.
 *  The price returned is in cents.
 */
export const calcNonDuoProductsTotalPrice = ({ products }) => {
  return products
    .filter(p => p.metadata?.skuType === 'EYE' && p.id !== EYE_CREAM_PRODUCT)
    .reduce((acc, product) => acc + product.one_time_price.price, 0)
}

export const calcSerumProductsTotalPrice = ({ products }) => {
  return products
    .filter(p => p.metadata?.skuType === 'SRM')
    .reduce((acc, product) => acc + product.one_time_price.price, 0)
}

export const calcSetDiscount = ({ products, priceId }) => {
  const product = findProductForPriceId({ products, priceId })
  if (!product.metadata?.isSystem) return 0
  const { price, oneTime } = findPriceInProduct({ product, priceId })
  const nonSystemProductsTotalPrice = calcNonSystemProductsTotalPrice({
    products,
    period: price.period,
    periodUnit: price.period_unit
  })
  return nonSystemProductsTotalPrice - price.price
}

export const findCorrespondingSystemPriceForProductPriceId = ({ priceId, products }) => {
  const systemProduct = products.find(product => product.metadata?.isSystem)
  const { price, oneTime } = findPriceInProducts({ priceId, products })
  if (oneTime) return systemProduct.one_time_price
  return findPriceInProductByPeriod({
    product: systemProduct,
    period: price.period,
    periodUnit: price.period_unit
  })
}

export const calcItemsSubtotal = ({ items, products }) => {
  return Object.entries(items).reduce((acc, [priceId, item]) => {
    const product = findProductForPriceId({ products, priceId })
    return acc + product.one_time_price.price * item.quantity
  }, 0)
}

export const calcItemsSetsDiscount = ({ items, products }) => {
  return Object.entries(items).reduce(
    (acc, [priceId, item]) => acc + calcSetDiscount({ products, priceId }) * item.quantity,
    0
  )
}

export const calcItemsSubscriptionSavings = ({ items, products }) => {
  return Object.entries(items).reduce((acc, [priceId, item]) => {
    const product = findProductForPriceId({ products, priceId })
    const { price, oneTime } = findPriceInProduct({ priceId, product })
    if (oneTime) return acc
    return acc + (product.one_time_price.price - price.price) * item.quantity
  }, 0)
}

export const getSystemProduct = products =>
  products.find(someProduct => someProduct.metadata?.isSystem)

export const getProductById = (products, id) => products.find(someProduct => someProduct.id === id)

export const getDefaultSystemSubscriptionPrice = product =>
  product.subscription_prices.find(
    priceObj =>
      priceObj.period === DEFAULT_SYSTEM_SUBSCRIPTION_PERIOD &&
      priceObj.period_unit === DEFAULT_SUBSCRIPTION_PERIOD_UNIT
  )

export const findPriceInProductByPeriod = ({ product, period, periodUnit }) =>
  product.subscription_prices.find(
    priceObj => priceObj.period === period && priceObj.period_unit === periodUnit
  )

export const formatPrice = (price = 0, decimals = 2) => {
  return formatAmount(price, decimals)
}

export const formatAmount = (price = 0, decimals = 2, returnAbsoluteValue = true) => {
  const isNegative = !isNaN(Number(price)) && Number(price) < 0
  const formattedAbsValue = Math.max(Math.abs(price) / 100, 0).toFixed(decimals)
  return isNegative && !returnAbsoluteValue ? formattedAbsValue * -1 : formattedAbsValue
}

export const formatPriceAsNumber = (price = 0, decimals = 2) =>
  Number(Math.max(price / 100, 0).toFixed(decimals))

export const formatTimer = number => {
  const seconds = number % 60
  const minutes = (number - seconds) / 60
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
}

export const calcOrderPrices = ({ items, frequency, frequencyUnit, products }) => {
  const subtotal = calcItemsSubtotal({ items, products })

  const itemsWithSystems = addSystemsToItemsAndClearEmpty({
    items,
    frequency,
    frequencyUnit,
    products
  })

  const setsDiscount = calcItemsSetsDiscount({
    items: itemsWithSystems,
    products
  })

  const subscriptionSavings = calcItemsSubscriptionSavings({
    items,
    products
  })

  // TODO - calc shipping
  const shipping = 0

  return {
    subtotal,
    setsDiscount,
    subscriptionSavings,
    shipping
  }
}

export const getSubscriptionTitle = subscription =>
  Object.entries(subscription.items)
    .map(([priceId, { quantity }]) => `${quantity} ${priceId}`)
    .join(', ')

export const findProductForSku = ({ sku, products }) => {
  const skuType = getSkuType(sku)
  return products.find(someProduct => someProduct.metadata.skuType === skuType)
}

export const getNewSubscriptionItemsForFrequency = ({
  items,
  frequency,
  frequencyUnit,
  products
}) => {
  let newItems = []
  for (const [priceId, values] of Object.entries(items)) {
    const product = findProductForPriceId({ products, priceId })
    const newPrice = findPriceInProductByPeriod({
      product,
      period: frequency,
      periodUnit: frequencyUnit
    })
    newItems = [...newItems, [newPrice.id, values]]
  }
  return Object.fromEntries(newItems)
}

export const isSystemSubscriptionInCart = ({ products, cartItems }) => {
  const { price, oneTime } = getSystemInCart({ products, cartItems })
  return price && !oneTime
}

export const isShopProductSubscriptionInCart = ({ productId, shopProducts, shopCartItems }) => {
  const { price, oneTime } = getShopProductInCart({ productId, shopProducts, shopCartItems })
  return price && !oneTime
}

export const getShopProductInCart = ({ productId, shopProducts, shopCartItems }) => {
  const priceId = Object.keys(shopCartItems).find(priceId => {
    const product = findCartProductWithPriceId({ products: shopProducts, priceId })
    return product?.id === productId
  })

  if (priceId) {
    return findPriceInProducts({ products: shopProducts, priceId })
  } else {
    return { price: null, oneTime: false }
  }
}

export const isProductSubscriptionInCart = ({ productId, products, cartItems }) => {
  if (productId === 'proven-system') {
    return isSystemSubscriptionInCart({ products, cartItems })
  } else {
    return isShopProductSubscriptionInCart({
      productId,
      shopProducts: products,
      shopCartItems: cartItems
    })
  }
}

export const getShopProductById = ({ productId, shopProducts }) => {
  return shopProducts.find(someProduct => someProduct.id === productId)
}

export const getSystemSubscriptionSelected = ({ products, cartItems, priceId }) => {
  const { price } = getSystemInCart({
    products,
    cartItems
  })
  const systemProduct = getSystemProduct(products)
  return price?.id !== systemProduct?.one_time_price.id
}

const isEyeCreamDuoProduct = product => {
  return product?.id === EYE_CREAM_PRODUCT
}

const isSerumProduct = product => {
  return product?.id === SERUM_PRODUCT
}

export const getEyeCreamDuoInCart = ({ products, cartItems }) => {
  const priceId = Object.keys(cartItems).find(priceId => {
    const product = findCartProductWithPriceId({ products, priceId })
    return isEyeCreamDuoProduct(product)
  })
  if (priceId) {
    return findPriceInProducts({ products, priceId })
  }
  return { price: null, oneTime: false }
}

export const getSerumInCart = ({ products, cartItems }) => {
  const priceId = Object.keys(cartItems).find(priceId => {
    const product = findCartProductWithPriceId({ products, priceId })
    return isSerumProduct(product)
  })
  if (priceId) {
    return findPriceInProducts({ products, priceId })
  }
  return { price: null, oneTime: false }
}

export const getEyeCreamDuoProduct = products =>
  products.find(someProduct => isEyeCreamDuoProduct(someProduct))

export const getSerumProduct = products => products.find(someProduct => isSerumProduct(someProduct))

export const getEyeCreamDuoSelected = ({ products, cartItems }) => {
  const { price } = getEyeCreamDuoInCart({
    products,
    cartItems
  })
  return !!price
}

export const getExpireDate = token => {
  return moment(jwt(token).exp * 1000).format('YYYY-MM-DD')
}

export const isExpired = token => {
  try {
    var dateNow = new Date()
    const diff = jwt(token).exp * 1000 - dateNow.getTime()
    return diff < 0 ? true : false
  } catch (err) {
    //console.log(`err: `, err)
    return true
  }
}

export const calcShippingRangeFromBillingDate = billingDateInSeconds => {
  const msPerDay = 60 * 60 * 24 * 1000
  const billingDateMs = billingDateInSeconds * 1000
  const shippingStartMs = moment(billingDateMs + 2 * msPerDay).valueOf() // add 2 days FIXME after PD-1199
  const shippingEndMs = moment(billingDateMs + 5 * msPerDay).valueOf() // add 5 days FIXME after PD-1199
  return { shippingStartMs, shippingEndMs }
}

export const isEyeCreamSubscription = subscription => {
  return Object.keys(subscription?.items || {})[0]?.includes('eye-cream') // FIXME use enum from Shared lib
}

export const isShopifyEyeCreamSubscription = subscription => {
  return subscription.items[0]?.product?.productType === 'Eye'
}

export const isSystemSubscription = subscription => {
  return Object.keys(subscription?.items || {})[0]?.includes('proven') // FIXME use enum from Shared lib
}

export const isShopifySystemSubscription = subscription => {
  return subscription.items[0]?.product?.productType === 'Skin'
}

export const isSpfSubscription = subscription => {
  return Object.keys(subscription?.items || {})?.some(item => item?.includes(['spf'])) // FIXME use enum from Shared lib
}

export const isNightCreamSubscription = subscription => {
  return Object.keys(subscription?.items || {})?.some(item => item?.includes('night-cream')) // FIXME use enum from Shared lib
}

export const isCleanserSubscription = subscription => {
  return Object.keys(subscription?.items || {})?.some(item => item?.includes('cleanser')) // FIXME use enum from Shared lib
}

export const isDayEyeCreamDuoSubscription = subscription => {
  return Object.keys(subscription?.items || {})?.some(
    item => item?.includes('day-eye-cream') && subscription?.items[item]?.quantity > 0
  ) // FIXME use enum from Shared lib
}

export const isNightEyeCreamDuoSubscription = subscription => {
  return Object.keys(subscription?.items || {})?.some(
    item => item?.includes('night-eye-cream') && subscription?.items[item]?.quantity > 0
  ) // FIXME use enum from Shared lib
}

export const isSerumSubscription = subscription => {
  return Object.keys(subscription?.items || {})[0]?.includes(SERUM_PRODUCT)
}

export const isShopifySerumSubscription = subscription => {
  return subscription.items[0]?.product?.productType === 'Serum'
}

export const isPriceIdASubscription = priceId =>
  priceId?.includes(PRICE_ID_SUBSTRING_FOR_SUBSCRIPTION_ITEM)
export const findSubscriptionPriceId = priceIds => priceIds?.find(isPriceIdASubscription)

export const isActiveSystemSubscription = subscription => {
  return (
    Object.keys(subscription?.items || {})[0]?.includes('proven') &&
    ['active', 'future', 'in_trial'].includes(subscription.status)
  ) // FIXME use enum from Shared lib
}

/**
 * product is system, max order
 * product other subscription, second order
 * others items, last order
 * @param product
 */
const getProductOrder = product => {
  if (product?.metadata.isSystem) {
    return 10
  } else if (product?.metadata?.skuType === 'EYE') {
    return 5
  } else {
    return 0
  }
}

/**
 *
 * @returns {string[]} return list of items in shopping cart order by :
 *  first subscription items
 *  then others
 */
export const getPriceIdOrdered = (priceIds, products) => {
  return priceIds
    .map(priceId => {
      const product = findCartProductWithPriceId({ products, priceId })

      return {
        priceId,
        order: getProductOrder(product)
      }
    })
    .sort((a, b) => {
      if (a.order < b.order) {
        return 1
      }
      if (a.order > b.order) {
        return -1
      }
      return 0
    })
    .map(item => item.priceId)
}

export const isOneTimeProduct = (priceId, products) => {
  const { oneTime } = findPriceInProducts({ priceId, products })
  return oneTime
}

/**
 * return TRUE if it is a mayor or liquid product, a mayor or liquid product can be eye-cream-duo or proven-system or serum so far
 * (or any product that is a combo of products)
 * @param product
 * @returns {boolean|*|boolean}
 */
export const isMajorProduct = product => {
  return (
    product?.metadata?.isSystem ||
    product?.id === EYE_CREAM_PRODUCT ||
    product?.id === SERUM_PRODUCT
  )
}

/**
 * search "priceId" in all "products" and return TRUE if the product exist and is buyable
 * @param products
 * @param priceId
 * @returns {boolean}
 */
const isSystemProductForPriceId = ({ products, priceId }) => {
  const product = products.find(someProduct => {
    return isPriceIdInProduct({ product: someProduct, priceId })
  })

  return isMajorProduct(product)
}

/**
 * search "priceId" in all "products" and return TRUE if the product is an accessory
 * @param products
 * @param priceId
 * @returns {boolean}
 */
const isSAccessoryForPriceId = ({ products, priceId }) => {
  const product = products.find(someProduct => {
    return isPriceIdInProduct({ product: someProduct, priceId })
  })

  return product?.metadata?.isAccessory
}

/**
 * this function return true if {product, priceId} are subscription item or a system product (skin care system or eye-cream-duo)
 * system product is a subscribable item but can be set to one-time purchase
 */
const isSubscribableItemOrSystemProduct = ({ product, priceId }, products) => {
  return isMajorProduct(product) || !isOneTimeProduct(priceId, products)
}

/**
 * this function return true if {product, priceId} are ONLY subscription item
 * system product is a subscribable item but can be set to one-time purchase
 */
const isSubscribableItem = ({ product, priceId }, products) => {
  return !isOneTimeProduct(priceId, products)
}

/**
 *
 * @returns return 2 groups one of subscription and one of others items :
 * {
 *   subscriptions: array with all subscription items,
 *   subscriptionsOneTime: array with all subscription items but selected to be only one time,
 *   others: one-time items and skip-the-line too
 * }
 */
export const getCartItemsGroupAndOrdered = (cartItems, products) => {
  return Object.keys(cartItems)
    .map(priceId => {
      const product = findCartProductWithPriceId({ products, priceId })

      return {
        priceId,
        order: getProductOrder(product),
        product
      }
    })
    .sort((a, b) => {
      if (a.order < b.order) {
        return 1
      }
      if (a.order > b.order) {
        return -1
      }
      return 0
    })
    .reduce(
      (prev, curr) => {
        if (
          isSubscribableItemOrSystemProduct(
            { product: curr?.product, priceId: curr?.priceId },
            products
          )
        ) {
          if (isSubscribableItem({ product: curr?.product, priceId: curr?.priceId }, products)) {
            // subscribable set as subscribable item
            prev.subscriptions.push(curr.priceId)
          } else {
            // subscribable set as one-time item
            prev.subscriptionsOneTime.push(curr.priceId)
          }
        } else {
          prev.others.push(curr.priceId)
        }
        return prev
      },
      { subscriptions: [], subscriptionsOneTime: [], others: [] }
    )
}

export const convertSubscriptionItemsToPayload = (subscriptionItems, newProduct) => {
  return Object.keys(subscriptionItems).map(itemId => {
    let quantity = subscriptionItems[itemId].quantity

    if (newProduct && newProduct.id == itemId) {
      quantity += 1
    }
    return {
      item_price_id: itemId,
      quantity
    }
  })
}

/**
 * this funciton merge cartItems with subscription item and convert it to payload
 * @param subscriptionItems
 * @param cartItems
 * @returns {{quantity: *, item_price_id: *}[]}
 */
export const convertSubscriptionItemsAndCartItemsToPayload = (subscriptionItems, cartItems) => {
  const cartItemsConverted = Object.keys(cartItems).reduce((prev, itemId) => {
    const quantity = cartItems[itemId].qty
    if (prev[itemId]) {
      prev[itemId].quantity += quantity
    } else {
      prev[itemId] = {
        quantity: quantity
      }
    }
    return prev
  }, subscriptionItems)

  const itemsMerged = convertSubscriptionItemsToPayload(cartItemsConverted)
  return itemsMerged
}

export const getSubscriptionImgPath = subscription => {
  const isEyeCreamSub = isEyeCreamSubscription(subscription)
  const isSerumSub = isSerumSubscription(subscription)
  const priceIdsIncluded = Object.keys(subscription.items)
    .filter(priceId => subscription.items[priceId].quantity)
    .map(priceId => priceId)

  if (isEyeCreamSub) {
    if (priceIdsIncluded.length < 2) {
      if (priceIdsIncluded[0].includes('day')) {
        return 'rud/eye-cream-day.png'
      } else {
        return 'rud/eye-cream-night.png'
      }
    } else {
      return `rud/eye-cream-2-products.png`
    }
  } else if (isSerumSub) {
    return 'rud/serum.png'
  } else {
    if (priceIdsIncluded.length < 3) {
      const containsSpf = priceIdsIncluded.find(priceId => priceId.includes('spf'))
      const containsNight = priceIdsIncluded.find(priceId => priceId.includes('night'))
      const containsCleanser = priceIdsIncluded.find(priceId => priceId.includes('cleanser'))
      const containsProvenSystem = priceIdsIncluded.find(priceId =>
        priceId.includes('proven-system')
      )
      if (priceIdsIncluded.length === 2) {
        if (containsSpf && containsNight) {
          return 'rud/system-spf-night.png'
        } else if (containsSpf && containsCleanser) {
          return 'rud/system-cleanser-spf.png'
        } else if (containsProvenSystem) {
          return `rud/system-3-products.png`
        } else {
          return 'rud/system-cleanser-night.png'
        }
      } else {
        if (containsSpf) {
          return 'rud/system-spf.png'
        } else if (containsNight) {
          return 'rud/system-night.png'
        } else {
          return 'rud/system-cleanser.png'
        }
      }
    } else {
      return `rud/system-3-products.png`
    }
  }
}

export const getEyeCreamSubscriptionSelected = ({ products, cartItems, priceId }) => {
  const { price } = getEyeCreamDuoInCart({
    products,
    cartItems
  })
  const systemProduct = getEyeCreamDuoProduct(products)
  return price?.id === (priceId || systemProduct?.one_time_price.id)
}

export const getDefaultEyeCreamDuoSubscriptionPrice = product =>
  product?.subscription_prices.find(
    priceObj =>
      priceObj.period === DEFAULT_EYE_CREAM_DUO_SUBSCRIPTION_PERIOD &&
      priceObj.period_unit === DEFAULT_SUBSCRIPTION_PERIOD_UNIT
  )

export const getDefaultEyeCreamDuoSubscriptionPriceOrFirst = eyeCreamProduct => {
  const product = getDefaultEyeCreamDuoSubscriptionPrice(eyeCreamProduct)
  return product || product?.subscription_prices[0]
}

export const getDefaultEyeCreamPriceFromSubscriptionPrice = eyeCreamProduct => {
  const subscription = getDefaultEyeCreamDuoSubscriptionPriceOrFirst(eyeCreamProduct)
  return subscription?.price
}

export const getDefaultSerumSubscriptionPriceOrFirst = eyeCreamProduct => {
  const product = getDefaultEyeCreamDuoSubscriptionPrice(eyeCreamProduct)
  return product || product?.subscription_prices[0]
}

export const getDefaultSerumPriceFromSubscriptionPrice = serumProduct => {
  const subscription = getDefaultSerumSubscriptionPriceOrFirst(serumProduct)
  return subscription?.price
}

export const getSortedSubscriptionIds = (subscription, excludeZeroQty) => {
  const items = cloneDeep(subscription.items)
  const subscriptionIds = Object.keys(items)
    .filter(subscriptionId => (excludeZeroQty ? items[subscriptionId].quantity : true))
    .map(subscriptionId => ({
      subscriptionId,
      ...items[subscriptionId]
    }))
    .sort((a, b) => a.rudOrder - b.rudOrder)
    .map(item => item.subscriptionId)
  return subscriptionIds
}

export const getIndividualItemCount = subscription => {
  return [
    ...Object.entries(subscription?.items || {}),
    ...Object.entries(subscription?.oneTimeItems || {})
  ].reduce((count, [_, item]) => count + item.quantity, 0)
}

export const getSubscriptionItemsCount = subscription => {
  return [...Object.entries(subscription?.items || {})].reduce(
    (count, [_, item]) => count + item.quantity,
    0
  )
}

export const getSubscriptionVersion = subscription => {
  return subscription.priceVersion
}

export const isSubscriptionVersionCurrent = subscription => {
  const version = getSubscriptionVersion(subscription)
  return version && version === 'current'
}

export const getSlugFromPath = path => normalizePath(path, false, false)

export const getShopProductFromPath = path => {
  const shopSkinAccountSlug = 'account/shop/skin/'
  const shopEyeAccountSlug = 'account/shop/eye/'
  // Find current product
  // NOTE: this logic is very hacky extraction from the slug.
  const isSkinProduct = path.includes('skin')
  const isEyeProduct = path.includes('eye')
  const isSerumProduct = path.includes('serum')
  let shopAccountSlug = shopSkinAccountSlug
  if (isEyeProduct) shopAccountSlug = shopEyeAccountSlug
  const productFromSlug = path.split(shopAccountSlug)[1]
  let showProducts = ALL_SHOP_PRODUCTS.find(x => x === productFromSlug)
  if (!showProducts && isEyeProduct) {
    showProducts = EYE_CREAM_PRODUCT
  } else if (!showProducts && isSkinProduct) {
    showProducts = SYSTEM_PRODUCT
  } else if (!showProducts && isSerumProduct) {
    showProducts = SERUM
  }
  return showProducts
}

export const normalizePath = (path, leadingSlash = false, trailingSlash = false) => {
  const urlRegex =
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
  const decorateSlug = slug => {
    if (slug.length === 0 && (leadingSlash || trailingSlash)) return '/'
    if (leadingSlash) slug = `/${slug}`
    if (trailingSlash) slug = `${slug}/`

    return slug
  }
  const cleanPathSegment = pathSegment => pathSegment.replace(/[\?#].*$/, '')

  // Falsey path means no path.
  if (!path) return null

  if (typeof path === 'string') {
    const normalizedPath = cleanPathSegment(path.match(urlRegex) ? new URL(path).pathname : path)
    const slug = normalizedPath
      .split('/')
      .filter(p => p.length > 0)
      .join('/')

    return decorateSlug(slug)
  }

  // Handle path segments
  if (Array.isArray(path)) {
    if (path.length > 0) {
      const lastIdx = path.length - 1

      path[lastIdx] = cleanPathSegment(path[lastIdx])
    }

    return decorateSlug(path.join('/'))
  }

  throw new Error('Unsupported path', path)
}

/**
 * add to the url params if exists
 * @param url
 * @returns {*}
 */
export const getUrlWithParams = (url, location) => {
  const params = location?.search
  let urlWithParams = ''

  if (params && params.length > 0) {
    urlWithParams = `${url}${params}`
  } else {
    urlWithParams = url
  }
  return urlWithParams
}

/**
 * filter from the cart the not-buyable Items and return cart
 * @param cartItems
 * @param products
 * @returns {{}}
 */
export const getOnlyBuyableItemsInCart = (cartItems, products) => {
  return Object.entries(cartItems).reduce((prev, [priceId, item]) => {
    const product = findProductForPriceId({ products, priceId })
    if (product) {
      return {
        ...prev,
        [priceId]: item
      }
    } else {
      return prev
    }
  }, {})
}

/**
 * clip the name when its length is greater than maxLength adding '...' at the end of the string.
 * Examples:
 *  clipFirstNameIfItsNeeded('aTextToBeClipped', 7) // returns: 'aTextTo...'
 *  clipFirstNameIfItsNeeded('aText', 6) // returns: 'aText'
 *  clipFirstNameIfItsNeeded('anotherText', 11) // returns: 'anotherText'
 * @param name
 * @param maxLength
 * @returns {string|*}
 */
export const clipFirstNameIfItsNeeded = (name, maxLength) =>
  name && name.length > maxLength ? `${name.slice(0, maxLength)}...` : name

const isIndividualProduct = productId => !COMBO_PRODUCTS.includes(productId)

/**
 * Discounts for buying a subscription instead of a one-time
 * @param cartItems
 * @param allItemsSubscribable
 * @param products
 */
export const getIndividualItemsSubscriptionDiscount = (
  cartItems,
  allItemsSubscribable,
  products
) => {
  // TODO on ENG-513 check the cause of this duplication and fix it where it's taking place, then remove this filter
  const { noDuplicatedProducts } = products.reduce(
    (idsAndProducts, p) => {
      if (!idsAndProducts.ids.has(p.id)) idsAndProducts.noDuplicatedProducts.push(p)
      idsAndProducts.ids.add(p.id)
      return idsAndProducts
    },
    { noDuplicatedProducts: [], ids: new Set() }
  )

  return allItemsSubscribable.reduce((acc, itemPriceId) => {
    let discount = 0
    const product = findCartProductWithPriceId({
      products: noDuplicatedProducts,
      priceId: itemPriceId
    })
    if (!product?.id || !isIndividualProduct(product.id)) return acc //there is no discount

    const subscriptionPrice = product.subscription_prices.find(p => p.id === itemPriceId)
    discount = product?.one_time_price?.price - subscriptionPrice.price
    return acc + discount
  }, 0)
}

/**
 * calculate discount in all subscriptions' combo.
 * for example if the user has a system product the discount is 199-127 = 72
 *
 * @param cartItems
 * @param allItemsSubscribable
 * @param products
 * @returns {*}
 */
export const getDiscountBySubscriptionCombo = (cartItems, allItemsSubscribable, products) => {
  // products comes with duplicated elements (objects with the same id)
  // TODO on ENG-513 check the cause of this duplication and fix it where it's taking place, then remove this filter
  const { noDuplicatedProducts } = products.reduce(
    (idsAndProducts, p) => {
      if (!idsAndProducts.ids.has(p.id)) idsAndProducts.noDuplicatedProducts.push(p)
      idsAndProducts.ids.add(p.id)
      return idsAndProducts
    },
    { noDuplicatedProducts: [], ids: new Set() }
  )

  const eyeProductsPriceWithoutDiscount = calcNonDuoProductsTotalPrice({
    products: noDuplicatedProducts
  })
  const systemProductsTotalPriceWithoutDiscount = calcNonSystemProductsTotalPrice({
    products: noDuplicatedProducts
  })
  const serumProductsTotalPriceWithoutDiscount = calcSerumProductsTotalPrice({
    products: noDuplicatedProducts
  })

  const systemProduct = getSystemProduct(noDuplicatedProducts)
  const systemSubscriptionPriceWithDiscount = getDefaultSystemSubscriptionPrice(systemProduct).price

  return allItemsSubscribable.reduce((acc, itemPriceId) => {
    let discount = 0
    const product = findCartProductWithPriceId({
      products: noDuplicatedProducts,
      priceId: itemPriceId
    })
    if (isIndividualProduct(product?.id)) return acc //there is no discount

    if (product?.metadata?.isSystem || product?.metadata?.isPartOfSystem) {
      const systemComboPriceWithDiscount = isOneTimeProduct(itemPriceId, noDuplicatedProducts)
        ? systemProduct.one_time_price.price
        : systemSubscriptionPriceWithDiscount

      discount = systemProductsTotalPriceWithoutDiscount - systemComboPriceWithDiscount
    } else if (product?.metadata?.skuType === 'EYE') {
      const eyeCreamComboPriceWithDiscount = isOneTimeProduct(itemPriceId, noDuplicatedProducts)
        ? product?.one_time_price?.price
        : getDefaultEyeCreamPriceFromSubscriptionPrice(product)

      discount = eyeProductsPriceWithoutDiscount - eyeCreamComboPriceWithDiscount
    } else if (product?.metadata?.skuType === 'SRM') {
      const serumComboPriceWithDiscount = isOneTimeProduct(itemPriceId, noDuplicatedProducts)
        ? product?.one_time_price?.price
        : getDefaultSerumPriceFromSubscriptionPrice(product)
      discount = serumProductsTotalPriceWithoutDiscount - serumComboPriceWithDiscount
    }

    return acc + discount
  }, 0)
}

export const formatPercentage = (stringWithPercentageValue, numberOfDecimals = 2) => {
  const replaceLast = (aString, pattern, replacement) =>
    aString.split('').reverse().join('').replace(pattern, replacement).split('').reverse().join('')

  const removeRightZerosAndLimitDecimals = numberAsString =>
    Number(Number(numberAsString).toFixed(numberOfDecimals)).toString()

  if (
    !stringWithPercentageValue ||
    stringWithPercentageValue === 'NaN' ||
    stringWithPercentageValue === '-'
  )
    return ''

  const percentageValueWithoutPercentSign = stringWithPercentageValue.trim().endsWith('%')
    ? replaceLast(stringWithPercentageValue, '%', '')
    : stringWithPercentageValue

  if (!isNaN(Number(percentageValueWithoutPercentSign)))
    return `${removeRightZerosAndLimitDecimals(percentageValueWithoutPercentSign)}%`

  const rangeOfPercentages = percentageValueWithoutPercentSign.split('-')
  if (rangeOfPercentages.length < 2) return stringWithPercentageValue //if it's neither a number nor a range of two numbers, we return the original value

  //if some element of ranges is not a number, we return the original value
  if (
    isNaN(Number(replaceLast(rangeOfPercentages[0], '%', ''))) ||
    isNaN(Number(rangeOfPercentages[1]))
  )
    return stringWithPercentageValue

  return `${removeRightZerosAndLimitDecimals(
    replaceLast(rangeOfPercentages[0], '%', '')
  )}-${removeRightZerosAndLimitDecimals(rangeOfPercentages[1])}%`
}

const subscriptionToAddIndividualProductFilterByProductId = {
  [SYSTEM_PRODUCT]: isSystemSubscription,
  [DAY_MOISTURIZER_PRODUCT]: isSystemSubscription,
  [NIGHT_CREAM_PRODUCT]: isSystemSubscription,
  [CLEANSER_PRODUCT]: isSystemSubscription,

  [EYE_CREAM_PRODUCT]: isEyeCreamSubscription,
  [NIGHT_EYE_CREAM_PRODUCT]: isEyeCreamSubscription,
  [DAY_EYE_CREAM_PRODUCT]: isEyeCreamSubscription,

  [SERUM_PRODUCT]: isSerumSubscription
}

/**
 * search in activSubscriptions array to find an activeSubscription where productId could be added.
 * If there is not one, it returns null
 *
 * @param productId
 * @param activeSubscriptions array of activeSubscriptions
 * @returns subscription
 */
export const lookForActiveSubsWhereIndividualProductCanBeAdded = (
  productId,
  activeSubscriptions
) => {
  const filter = subscriptionToAddIndividualProductFilterByProductId[productId]
  return activeSubscriptions && filter && activeSubscriptions.find(filter)
}

export const getDefaultSerumSubscriptionPrice = product =>
  product.subscription_prices.find(
    priceObj =>
      priceObj.period === DEFAULT_SERUM_SUBSCRIPTION_PERIOD &&
      priceObj.period_unit === DEFAULT_SUBSCRIPTION_PERIOD_UNIT
  )

export const normalizePathInHtml = html => {
  return html.replace('"https://www.provenskincare.com', '"')
}

export const findSkipTheLineInLineItems = (lineItems, currency) =>
  lineItems &&
  lineItems.find(lineItem => lineItem.entityId === `${SKIP_THE_LINE_PRODUCT_ID}-${currency}`)

export const addValuesToObjectOnlyIfDefined = (destination, newValues = {}) => {
  const newValuesFilter = Object.keys(newValues).reduce((prev, curr) => {
    if (newValues[curr]) {
      prev[curr] = newValues[curr]
    }

    return prev
  }, {})

  return {
    ...destination,
    ...newValuesFilter
  }
}

// Manually split UTM params from the search URL
export const getTokensFromSearch = search => {
  let searchFormatted = search
  if (searchFormatted?.charAt(0) === '?') {
    searchFormatted = searchFormatted.substring(1)
  }
  return searchFormatted?.split('&') || ''
}

/**
 * This function check the next possible billing date
 * eg:
 *   - If user selected a bigger frequency, for example, change from 6 weeks to 8 week.
 *     Then we push the next formulation date to 2 weeks further. For example from May 18 to Jun 1st
 *   - If user selected a smaller frequency,  for example, change from 6 weeks to 4 week.
 *     Then we move the next formulation date up 2 weeks. For example from May 18 to May 4
 *   - If user selected a smaller frequency,  for example, change from 6 weeks to 4 week,
 *     but the next formulation date already passed. We will move the formulation date to the soonest possible date,
 *     let’s say it’s May 7. Then we move the next billing tate to tomorrow
 * @param subscription
 * @param modifiedSubscription
 * @returns {{isBeforeToday: boolean, newBillingDate: moment.Moment}|undefined}
 *  isBeforeToday if it is true then we move the date to tomorrow because the billing date already pass
 *  newBillingDate is the next billing date
 */
export const getNewBillingDate = (subscription, modifiedSubscription) => {
  let newBillingDate, adjusted
  const frequencyUnits = subscription?.frequencyUnit
  const diff = Number(modifiedSubscription?.frequency) - Number(subscription?.frequency)

  const today = moment()

  if (diff != 0) {
    let newBillingDate = moment.unix(subscription.nextBillingAt).add(diff, frequencyUnits)
    const isBeforeToday = newBillingDate.isBefore(today, 'day')

    // check new billing date is not before today, if it is then set the time to today
    // chargebee only allow me to modify the billingDate the tomorrow
    newBillingDate = isBeforeToday ? today.add(1, 'day') : newBillingDate

    return { newBillingDate, isBeforeToday }
  }
  return undefined
}

/**
 * returns true if it has the address fields that are required for the BE (preview endpoint)
 * @param addressFields
 * @returns {boolean}
 */
export const hasRequiredAddressFields = addressFields => {
  return !!(
    addressFields?.city &&
    addressFields?.country &&
    addressFields?.zip &&
    addressFields?.address1
  )
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}
