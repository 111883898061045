import { PUBLIC_STOREFRONT_API_TOKEN, PUBLIC_STORE_DOMAIN } from '../constants/constants'
import { createStorefrontApiClient } from '@shopify/storefront-api-client'

export const getGlobalId = (object_name, id) => `gid://shopify/${object_name}/${id}`
export const getProductVariantGlobalId = id => getGlobalId('ProductVariant', id)
export const getProductGlobalId = id => getGlobalId('Product', id)

// export const storefrontClient = createStorefrontClient({
//   storeDomain: PUBLIC_STORE_DOMAIN,
//   publicStorefrontToken: PUBLIC_STOREFRONT_API_TOKEN
// })
export const storefrontClient = createStorefrontApiClient({
  storeDomain: PUBLIC_STORE_DOMAIN,
  apiVersion: '2023-10',
  publicAccessToken: PUBLIC_STOREFRONT_API_TOKEN
})
